<template>
  <v-app data-app>
    <v-container>
      <v-row>
        <v-card class="mb-0" style="background: transparent;box-shadow: none">
          <v-card-title style="text-transform: capitalize;font-size: 1rem;" class="px-0 pt-0">
            <v-menu
                ref="menu"
                v-model="menu_week_date"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    :value="week_date ? $root.dateToRus(week_date) : ''"
                    readonly
                    style="max-width: 100px;margin-right: 10px"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="week_date"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  locale="ru"
                  :reactive="true"
                  @input="menu = false; getWeek()"
              >
              </v-date-picker>
            </v-menu>
            {{ $moment(week_date).format('MMMM YYYY') }}
            <v-chip color="white" class="ml-2 mt-4 mt-md-0">{{ $root.printCost(monthInfo.total) }} /
              {{ $root.printCost(monthInfo.plan) }} (<span>{{
                  $root.printCost(monthInfo.total - monthInfo.plan)
                }}</span>)
              <span class="ml-2">{{ monthInfo.rate }}<v-icon color="amber" size="16">mdi-star</v-icon></span>
            </v-chip>
          </v-card-title>
          <v-card-text v-if="week_loading" style="display: inline-flex" class="pb-0 overflow-x-auto px-0">
            <v-skeleton-loader type="card" v-for="i in 5" :key="i" class="mr-2" width="264" height="86"/>
          </v-card-text>
          <v-card-text v-else style="display: inline-flex" class="pb-0 overflow-x-auto px-0">
            <v-card v-for="(week, index) in weeks" :key="index" class="mr-2" @click="week_date=week.date;getWeek();"
                    :style="index===currentWeek ? 'background-color: #aef2f6 !important;' : ''">
              <v-card-title style="font-size: 18px;justify-content: space-between" class="py-2">Неделя {{ index + 1 }}
                ({{ week.start }} - {{ week.end }})
                <span v-if="index<=currentWeek" style="font-size: 14px;font-weight: normal;display: flex">{{
                    week.rate
                  }}<v-icon color="amber"
                            size="16">mdi-star</v-icon></span>
              </v-card-title>
              <v-card-text style="white-space: nowrap">{{ $root.printCost(week.total) }} /
                {{ $root.printCost(week.plan) }} (<span
                    :style="index<=currentWeek ? week.total - week.plan>=0 ? 'color: green' : 'color: red' : ''">{{
                    $root.printCost(week.total - week.plan)
                  }}</span>)
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text class="pa-0">
            <v-row v-if="week_loading" style="display: inline-flex" class="pb-0 overflow-x-auto px-0">
              <v-skeleton-loader type="card" v-for="i in 7" :key="i" class="mr-2 mb-2" width="150" height="150"/>
            </v-row>
            <v-row v-else>
              <v-card style="margin-right: 10px !important;width: 150px;height: 150px;" class="mr-4 stats_day"
                      v-for="(stat, index) in week"
                      :class="stat.date===today ? 'stats_day_selected' : ''"
                      :key="index">
                <v-card-title style="padding: 10px 0 0 10px;font-size: 16px;line-height: 16px">
                  {{ $moment(stat.date).format('DD.MM') }},
                  {{ $moment(stat.date).locale('ru').format('dd').toUpperCase() }}
                </v-card-title>
                <v-card-text style="padding: 10px;line-height: 16px">
                  <table
                      v-for="(row, index) in [[stat.total, stat.marks[1], $store.state.user.city.country.currency],[stat.workers_count, Math.ceil(stat.marks[1]/$store.state.user.revenuePerCleaner), 'клин']]">
                    <tbody>
                    <tr>
                      <td>{{ row[0] }} {{ row[2] }} -</td>
                    </tr>
                    <tr>
                      <td>{{ row[1] }} {{ row[2] }} =</td>
                    </tr>
                    <tr>
                      <td style="color: green" v-if="parseInt(row[0] - row[1])>0">+{{ parseInt(row[0] - row[1]) }}
                        {{ row[2] }}
                      </td>
                      <td v-else-if="parseInt(row[0] - row[1])===0">{{ parseInt(row[0] - row[1]) }} {{ row[2] }}</td>
                      <td v-else style="color: red">{{ parseInt(row[0] - row[1]) }} {{ row[2] }}</td>
                    </tr>
                    </tbody>
                    <v-divider v-if="index<1" class="my-1"></v-divider>
                  </table>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="false">
        <v-card style="max-width: 550px">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <h4 class="mb-0">Статистика за неделю</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <LineChartGenerator
                    :chart-options="lineChartOptions"
                    :chart-data="barChartData"
                    chart-id="bar-chart"
                    :width="400"
                    :height="300"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-row>
      <v-card>
        <DatePeriod style="position: sticky;top: 0;background: white;z-index: 10;" @change="load"
                    v-model="date"></DatePeriod>
        <v-card-text class="pa-0">
          <v-skeleton-loader v-if="analytics_loading" type="table-tbody"/>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Источник</th>
                <th>Показы</th>
                <th>Клики</th>
                <th>CPC</th>
                <th>CTR</th>
                <th>Расход</th>
                <th>Заказов</th>
                <th>CV</th>
                <th>CPL</th>
                <th>Не отмененных заказов</th>
                <th>Отмененных заказов</th>
                <th>CPO</th>
                <th>ROAS</th>
                <th>ДРР</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, instrument) in analytics">
                <AnalyticsRow :key="instrument"
                    :facebook-auth="$refs.facebookAuth" :vk-auth="$refs.vkAuth"
                              :yandex-auth="$refs.yandexAuth"
                              :google-auth="$refs.googleAuth" @click.native="loadInstrumentOrders(instrument, '')"
                              :row="row" :instrument="instrument" :opened="openedInstrument===instrument"/>
                <template v-if="openedInstrument===instrument" v-for="(campaign, name) in row.campaigns">
                  <AnalyticsRow padding="20" :style="'background:#eee'"
                                @click.native="loadInstrumentOrders(instrument, name)" :row="campaign"
                                :instrument="name"
                                :opened="openedCampaign===name"/>
                  <AnalyticsOrdersTable :title="`Заказы по кампании <span style='color: #1976d2'>${name}</span>`"
                                        v-if="openedCampaign===name" :orders="analyticsOrders"/>
                </template>
                <AnalyticsOrdersTable
                    :title="`Заказы из источника <span style='color: #1976d2'>${$root.capitalize(instrument)}</span>`"
                    v-if="openedInstrument===instrument && openedCampaign===''" :orders="analyticsOrders"/>
              </template>
              </tbody>
            </template>
          </v-simple-table>

          <DashboardStats :cities="[$store.state.user.city.id]" :date="date"/>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-text style="height: 100%;position: relative">
              <v-select @change="updateOrdersMap" class="mr-2 mt-0 pt-0" style="max-width: 300px;display: inline-block"
                        label="Тип уборки" multiple
                        v-model="ordersMapFilter.types"
                        :items="$store.state.toSelectItems($store.state.cleanTypes)"/>
              <v-select @change="updateOrdersMap" class="mt-0 pt-0" style="max-width: 300px;display: inline-block"
                        label="Кол-во комнат" multiple
                        v-model="ordersMapFilter.rooms"
                        :items="[1,2,3,4,5]"/>
              <span class="ml-2">{{ ordersMapFiltered.length }} заказов отображается</span>
              <div
                  style="width: calc(100% - 30px);height:calc(100vh - 120px);display: flex;justify-content: center;align-items: center;position: absolute;z-index: 1;background: #ffffffa3"
                  v-if="ordersMapHidden">
                <v-btn style="margin: 10px 0" @click="ordersMapHidden=false">Отобразить заказы на карте
                  ({{ ordersMapFiltered.length }} шт.)
                </v-btn>
              </div>
              <yandex-map style="width: 100%;height:calc(100vh - 120px);border-radius: 3px" :zoom="11" :coords="coords"
                          :controls="[]">
                <ymap-marker
                    v-for="order in ordersMapFiltered.filter(o=>!ordersMapHidden)"
                    :key="order.n_id"
                    :coords="[parseFloat(order.address.lat)+$store.state.rand(-mapDiff, +mapDiff),parseFloat(order.address.lng)+$store.state.rand(-mapDiff, +mapDiff)]"
                    :marker-id="+order.n_id"
                    marker-type="placemark"
                    :icon="{color:{standart:'green', general:'orange', remont:'red', other: 'pink'}[order.type], content: order.address.flattype}"
                >
                  <template slot="balloon">
                    <div>
                      <router-link :to="'/orders/'+order.n_id" target="_blank">Открыть</router-link>
                      <div><b>{{ $store.state.cleanTypes[order.type] }}, {{ order.address.flattype }}-комн
                        {{ $root.printCost(order.total) }}</b></div>
                      <div>{{ order.datetimecleanObj.time }}</div>
                      <Address :value="order.address"/>
                      <CleanerLink v-for="cleaner in order.cleaners" :value="cleaner"/>
                    </div>
                  </template>
                </ymap-marker>
              </yandex-map>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" style="display: flex;flex-direction: column;">
          <v-row style="display: flex;flex-direction: row;flex-wrap: nowrap;width: 100%;">
            <v-col v-for="(balance, name) in balances" cols="4" class="pl-0 mr-2 pt-0">
              <v-card>
                <v-card-text>{{ name }}</v-card-text>
                <v-card-title class="py-0">{{ balance }}</v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="$store.state.user.rights.includes('finance')" class="mobile-col-basis px-0">
              <v-card>
                <v-card-title>
                  Расчет
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-row>
                      <v-col>
                        <v-select
                            v-model="dashboardForm.month"
                            :items="$store.state.months"
                            item-value="value"
                            item-text="month"
                            type="month"
                            outlined
                            @change="getFinance()"></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="dashboardForm.year"
                            :items="years"
                            item-value="value"
                            type="year"
                            outlined
                            @change="getFinance()"></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea placeholder="Комментарий" v-model="finance.comment" rows="3" outlined></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row v-for="(item, name) in financeLabel" :key="name">
                      <v-col cols="4">{{ item }}</v-col>
                      <v-col cols="8">
                        <v-text-field
                            :readonly="name==='fee' || name==='royalty'"
                            v-model="finance[name]"
                            outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn block @click="saveFinance()" :loading="loading_saveFinance">Сохранить</v-btn>
                    <p style="margin: 10px 0 0 10px;">* ЗП клинеров считается автоматически</p>
                    <v-row>
                      <v-col cols="8">Себестоимость в день</v-col>
                      <v-col cols="4">
                        <v-text-field
                            readonly
                            disabled
                            :value="finance.level_1"
                            outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">План в день</v-col>
                      <v-col cols="4">
                        <v-text-field
                            readonly
                            disabled
                            :value="finance.level_2"
                            outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">Выручка в месяц для покрытия себеса</v-col>
                      <v-col cols="4">
                        <v-text-field
                            readonly
                            disabled
                            :value="(Math.ceil((parseInt(finance.order_income)+parseInt(finance.order_income2))/1000) ||'---')+' к'"
                            outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">ЗП клинеров в месяц при выручке, покрывающей себес</v-col>
                      <v-col cols="4">
                        <v-text-field
                            readonly
                            disabled
                            :value="(Math.ceil((parseInt(finance.cleaners_salary)+parseInt(finance.cleaners_salary2))/1000) ||'---')+' к'"
                            outlined></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
    <GoogleAuth ref="googleAuth"></GoogleAuth>
    <YandexAuth ref="yandexAuth"></YandexAuth>
    <FacebookAuth ref="facebookAuth"></FacebookAuth>
    <VkAuth ref="vkAuth"/>
  </v-app>
</template>

<script>
import DatePeriod from "../../components/DatePeriod";
import ClientLink from "../../components/ClientLink";
import OrderAddress from "../../components/OrderAddress";
import OrderStatus from "../../components/OrderStatus";
import GoogleAuth from "./GoogleAuth";
import YandexAuth from "./YandexAuth";
import FacebookAuth from "./FacebookAuth";
import InfoTip from "../../components/InfoTip";
import OrdersSum from "../../components/OrdersSum";
import AnalyticsRow from "../../components/AnalyticsRow";
import AnalyticsOrdersTable from "../../components/AnalyticsOrdersTable";
import {Doughnut, Line as LineChartGenerator} from "vue-chartjs/legacy"
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement, CategoryScale, LinearScale, PointElement
} from 'chart.js'
import VkAuth from "./VkAuth";
import Address from "../../components/Address";
import CleanerLink from "../../components/CleanerLink";
import DashboardStats from "../../components/DashboardStats";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: 'Dashboard',
  components: {
    DashboardStats,
    CleanerLink,
    Address,
    VkAuth,
    AnalyticsOrdersTable,
    AnalyticsRow,
    OrdersSum,
    InfoTip, FacebookAuth, YandexAuth, GoogleAuth, OrderStatus, OrderAddress, ClientLink, DatePeriod,
    Doughnut, LineChartGenerator
  },
  data() {
    let years = [];
    for (let i = 2021; i <= parseInt(this.$moment().format('YYYY')) + 1; i++) {
      years.push(i + '')
    }
    let currentYear = this.$moment().format('YYYY');
    let currentMonth = this.$moment().format('M')
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      years,
      mapDiff: 0.0002,
      menu_date_to: false,
      menu_date_from: false,
      menu_week_date: false,
      ordersMapFilter: {types: [], rooms: []},
      loading: false,
      // coords: [+this.$store.state.user.city.lat || 0, +this.$store.state.user.city.lng || 0],
      date: [today, today],
      date_from: localStorage.getItem('') || today,
      date_to: today,
      week_date: today,
      today: today,
      balances: {},
      analytics: {},
      analytics_loading: false,
      weekInfo: {},
      monthInfo: {},
      week: [],
      week_loading: false,
      ordersMapFiltered: [],
      ordersMap: [],
      ordersMapHidden: true,
      openedInstrument: '',
      openedCampaign: '',
      analyticsOrders: [],
      currentWeek: [],
      weeks: [],
      finance: {},
      financeLabel: {
        plan: 'План на месяц',
        office: 'Аренда офиса',
        adv: 'Реклама',
        salary: 'Зп менеджеров',
        phone: 'Телефония',
        consumables: 'Расходники',
        other: 'Другое',
      },
      dashboardForm: {year: currentYear, month: currentMonth},
      loading_saveFinance: false,
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {},
        scales: {
          A: {
            type: 'linear',
            position: 'left',
          },
          B: {
            type: 'linear',
            position: 'right',
          }
        }
      },
    }
  },
  methods: {
    updateOrdersMap() {
      this.ordersMapFiltered = [];
      this.$nextTick(() => {
        this.ordersMapFiltered = this.ordersMap.filter(o => {
          // if (![69809, 69908,69833].includes(+o.n_id)) return false;
          return (!this.ordersMapFilter.types.length || this.ordersMapFilter.types.includes(o.type)) &&
              (!this.ordersMapFilter.rooms.length || this.ordersMapFilter.rooms.includes(+o.address.flattype));
        });
      })
    },
    barChartData() {
      let barLabels = []
      let barData = []
      let workersCount = []
      this.week.forEach(stat => {
        barLabels.push(`${this.$moment(stat.date).format('DD.MM')}`)
        barData.push(stat.total)
        workersCount.push(stat.workers_count)
      })
      return {
        labels: barLabels,
        datasets: [
          {
            label: 'Сумма заказов',
            backgroundColor: '#f87979',
            data: barData,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.4,
            yAxisID: 'A',
          },
          {
            label: 'Кол-во клинеров',
            backgroundColor: '#7992f8',
            data: workersCount,
            datalabels: {
              align: 'end',
              anchor: 'start'
            },
            tension: 0.4,
            yAxisID: 'B',
          }
        ]
      }
    },
    load() {
      this.loadInstrumentOrders('');
      this.analytics = [];
      this.analytics_loading = true;
      this.$store.state.server.request('dashboard/analytics', {date: this.date}, (data) => {
        this.analytics_loading = false;
        this.analytics = data.response;
        this.balances = data.balances;
      }, ()=>{
        this.analytics_loading = false;
      });

      this.loadOrdersMap();
      this.getWeek();
    },
    loadOrdersMap() {
      this.$store.state.server.request('dashboard/ordersMap', {date: this.date}, (data) => {
        this.ordersMap = data.response;
        this.ordersMapHidden = true;
        this.updateOrdersMap();
      });
    },
    loadInstrumentOrders(instrument, campaign) {
      console.log('loadInstrumentOrders', {instrument, campaign});
      this.openedInstrument = this.openedInstrument === instrument && !campaign ? '' : instrument
      this.openedCampaign = this.openedCampaign === campaign ? '' : campaign
      if (!this.openedInstrument && !this.openedCampaign) {
        this.analyticsOrders = [];
        return;
      } else {
        this.analyticsOrders = [{}]
      }
      this.$store.state.server.request('dashboard/analyticsOrders', {
        date: this.date,
        instrument: this.openedInstrument,
        campaign: this.openedCampaign
      }, (data) => {
        this.analyticsOrders = data.response;
      });
    },
    setWeek(week) {
      this.week = week.week;
      this.weeks = week.weeks;
      this.monthInfo = week.monthInfo;
      this.currentWeek = week.currentWeek;
    },
    getFinance() {
      this.finance = {};
      this.$store.state.server.request('finance/get', {
        year: this.dashboardForm.year,
        month: this.dashboardForm.month
      }, (data) => {
        this.finance = data.response;
      })
    },
    getWeek() {
      this.week_loading = true;
      this.$store.state.server.request('dashboard/week', {
        date: this.week_date,
      }, (data) => {
        this.week_loading = false;
        this.setWeek(data.response)
      }, () => {
        this.week_loading = false;
      })
    },
    saveFinance() {
      this.loading_saveFinance = true;
      this.$store.state.server.request(`finance/update/${this.finance.id}`, this.finance, (data) => {
        this.loading_saveFinance = false;
        this.finance = data.response;
        this.$root.notify('Финансовые данные сохранены', 'success')
      }, (data) => {
        this.loading_saveFinance = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },

  },
  mounted() {
    this.load();
    this.getFinance()
  },
  computed: {
    coords() {
      return [+this.$store.state.user.city.lat || 0, +this.$store.state.user.city.lng || 0]
    },
  }
}
</script>
<style>
.dashboard_cards .v-list-item__content {
  padding: 0 !important;
}
</style>
